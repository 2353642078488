import React, { useState, useEffect, useRef } from 'react'
import {
  IonButton,
  IonContent,
  IonImg,
  IonInput,
  IonItem,
  IonList,
  IonPage,
} from '@ionic/react'
import './Login.scss'
import { toastr } from 'react-redux-toastr'

import { connectLogin } from './login.connect'
import ReCAPTCHA from 'react-google-recaptcha'
//import { RECAPTCHA } from '../../config/app.config'

import gameCareLogo from '../../assets/images/game-care.svg'
import restrictedRegionsLogo from '../../assets/images/restricted-regions-logo.svg'
import underageLogo from '../../assets/images/underage-logo.svg'
import LoginPopover from '../../components/LoginPopover/Login-popover'

import RRLogo from '../../assets/images/restricted-regions-logo-popover.svg'
import UndLogo from '../../assets/images/underage-logo-popover.svg'
import { useHistory } from 'react-router'

import UpdatePopUp from '../../components/UpdatePopUp/index.js'
import { useSelector } from 'react-redux'

const rrContent = `<p>Access to this site is restricted in certain geographical territories. Customers residing in
                    the
                    listed territories are prohibited from accessing the site and its services.</p>

                <p>The restricted territories are:</p>

                <p>Afghanistan, Algeria, Australia, Bosnia and Herzegovina, Bulgaria, Canada, China (People's
                    Republic of China), Cuba, Cyprus, Denmark, Ethiopia, France (and French territories), Germany,
                    Gibraltar, Iran (Islamic Republic of Iran), Iraq, Ireland , Italy, Korea, Democratic People's
                    Republic of, Lao (People's Democratic Republic of), Malta, Myanmar, Netherlands, New
                    Zealand,
                    Poland, Portugal, Puerto Rico, Qatar, Romania, Singapore, Slovakia, South Africa, Spain,
                    Sudan,
                    Syrian Arab Republic, Turkey, Uganda, United Kingdom, United States (and US Territories),
                    Yemen.
                </p>`

const undContent = `<p>Protections of minors</p>

                <p>It is illegal for anybody under the age of 18 to gamble.</p>

                <p>We encourage parents consider the use of internet use protection tools. 
                You may find the following links useful.</p>`

const Login = ({ auth, recaptcha, actions, version }) => {
  const refRecaptcha = useRef(null)
  const B2C = useSelector(state => state.B2C)
  const history = useHistory()
  let [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const [showRestrictedPopover, setShowRestrictedPopover] = useState(false)
  const [showUnderagePopover, setShowUnderagePopover] = useState(false)

  useEffect(() => {
    if (!auth) {
      // if we need to fix token on local
      // if ((window.location.host === "localhost:3000" || window.location.host === "d1xzve0qf943ll.cloudfront.net" )) actions.getRecaptchaSuccess(RECAPTCHA)
      // else {
      console.log('request captcha')
      actions.requestRecaptcha()
      // }
    }
  }, [auth])
  
  const getBalance = (memberCode) => {
    actions
      .requestBalance(memberCode)
      .then((res) => {
       if(B2C && res.data.result.availBalance < 100) {
        // history.push("/deposit")
        }
      })
      .catch((e) => console.log('balance error = ', e))
  }

  const submit = async () => {
    try {
      if (!username || !password || loading) return
      setLoading(true)
      const recaptchaResponse = await refRecaptcha.current.executeAsync()
      let newUserName = ''
      if(B2C) {
        newUserName = `91_${username}`
        await actions
        .requestAuth({
          username: newUserName,
          password,
          recaptchaResponse,
          answer: '',
          token: '',
        })
        .then((res) => {
          setLoading(false)
          getBalance(res.data.result.memberCode)
        }).
          catch((e)=>{
            console.log(e);
          })
      } else {
        await actions
          .requestAuth({
            username,
            password,
            recaptchaResponse,
            answer: '',
            token: '',
          })
          .then((res) => {
            setLoading(false)
          }).
          catch((e)=>{
            console.log(e);
          })
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
      toastr.error('', `${e}`)
      if (refRecaptcha.current) refRecaptcha.current.reset()
    }
  }

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      submit()
    }
  }

  const changeName = (e) => {
    setUsername(e.detail.value)
  }

  const changePass = (e) => {
    setPassword(e.detail.value)
  }


  // update pop-up
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    let versionNow = version

    if (version) {
      if (localStorage.getItem('versionNow') === versionNow) {
        return console.log('version up to date')
      } else if (!localStorage.getItem('versionNow')) {
        localStorage.setItem('versionNow', versionNow)
      } else if (localStorage.getItem('versionNow') != versionNow) {
        setIsOpen((isOpen) => !isOpen)
        localStorage.setItem('versionNow', versionNow)
      }
    }
  }, [version])
  // end update pop-up

  return (
    <IonPage>
      <IonContent class="login-content" fullscreen>
        <div
          className={`content-wrapper ${
            (showRestrictedPopover || showUnderagePopover) && 'blur'
          }`}
        >
          <div className="login-form">
            <div className="logo"></div>
            <form
              className="login-ion-form"
              onSubmit={(e) => {
                e.preventDefault()
                submit()
              }}
            >
              {B2C ? (
                <IonList class="login-content__list">
                <input
                  formcontrolname="username"
                  placeholder="10 Digit Phone Number"
                  name="username"
                  type="text"
                  value={`${username}`}
                  autoComplete="Phone Number"
                  onChange={(e) => setUsername(e.target.value)}
                  hidden
                />
                <input
                  formcontrolname="password"
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={password}
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                  hidden
                />
                  <div className='b2c_login'>
                    <IonItem>
                      <div className='placeholder'>+91</div>
                      <IonInput
                        formcontrolname="username"
                    placeholder="10 Digit Phone Number"
                        name="username"
                        type="text"
                        autoComplete="username"
                        value={`${username}`}
                        onKeyDown={onKeyDown}
                        onIonChange={changeName}
                      />
                    </IonItem>
                  </div>
                <IonItem>
                  <IonInput
                    formcontrolname="password"
                    placeholder="Password"
                    name="password"
                    type="password"
                    value={password}
                    autoComplete="current-password"
                    onKeyDown={onKeyDown}
                    onIonChange={changePass}
                  />
                </IonItem>
              </IonList>
              )
              : (
                <IonList class="login-content__list">
                <input
                  formcontrolname="username"
                  placeholder="Login Name"
                  name="username"
                  type="text"
                  value={`${username}`}
                  autoComplete="username"
                  onChange={(e) => setUsername(e.target.value)}
                  hidden
                />
                <input
                  formcontrolname="password"
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={password}
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                  hidden
                />
                <IonItem>
                  <IonInput
                    formcontrolname="username"
                    placeholder="Login Name"
                    name="username"
                    type="text"
                    autoComplete="username"
                    value={`${username}`}
                    onKeyDown={onKeyDown}
                    onIonChange={changeName}
                  />
                </IonItem>
                <IonItem>
                  <IonInput
                    formcontrolname="password"
                    placeholder="Password"
                    name="password"
                    type="password"
                    value={password}
                    autoComplete="current-password"
                    onKeyDown={onKeyDown}
                    onIonChange={changePass}
                  />
                </IonItem>
              </IonList>
              )
            }


              <button
                className="login-button"
                disabled={!username || !password || loading}
                type="submit"
              >
                Log in
              </button>
                {B2C && (
                  <div className="redirect-container">
                      <div className="forgot"><a href="https://www.lotus365.com/?forgot">Forgot password?</a></div>
                      <div className="sign"><a href="https://www.lotus365.com/?sign">Sign up</a></div>
                  </div>
                )}
            </form>
            {recaptcha ? (
              <ReCAPTCHA
                ref={refRecaptcha}
                sitekey={recaptcha}
                badge="bottomright"
                size="invisible"
              />
            ) : null}
          </div>
          <div className="footer-logos">
            <IonImg
              class="footer-logo"
              onClick={() =>
                window.open('https://www.gamcare.org.uk/', '_system')
              }
              src={gameCareLogo}
            />
            <IonImg
              class="footer-logo"
              onClick={() => setShowRestrictedPopover(true)}
              src={restrictedRegionsLogo}
            />
            <IonImg
              class="footer-logo"
              src={underageLogo}
              onClick={() => setShowUnderagePopover(true)}
            />
          </div>
        </div>
        <LoginPopover
          title="Restricted Territories"
          image={RRLogo}
          content={rrContent}
          open={showRestrictedPopover}
          onDidDismiss={() => setShowRestrictedPopover(false)}
        />
        <LoginPopover
          title="Underage gambling is an offence"
          footer={true}
          image={UndLogo}
          content={undContent}
          open={showUnderagePopover}
          onDidDismiss={() => setShowUnderagePopover(false)}
        />
        <UpdatePopUp isOpen={isOpen} />
      </IonContent>
    </IonPage>
  )
}

export default connectLogin()(Login)
